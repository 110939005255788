<template>
  <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
    <vx-card>
      <div class="flex items-center justify-between">
        <div class="flex flex-col space-y-2">
          <h2 class="text-xl font-medium">Atendimentos encerrados</h2>
          <h3 :key="compKey" class="font-semibold text-3xl">
            {{
              endedConversations && endedConversations.length > 0
                ? this.endedConversations
                    .map((el) => el.value)
                    .reduce((a, b) => a + b)
                : 0
            }}
          </h3>
        </div>
        <!-- <feather-icon
          icon="HelpCircleIcon"
          svgClasses="w-6 h-6 text-primary"
        ></feather-icon> -->
      </div>
      <div>
        <vue-apex-charts
          type="area"
          height="200"
          :series="series"
          :options="chartOptions"
          :key="chartKey"
        ></vue-apex-charts>
      </div>
    </vx-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  mounted() {},
  props: {
    chartData: Object,
    endedConversations: Array,
    chartKey: Number,
  },
  components: {
    VueApexCharts,
  },
  data() {
    return {
      series: [
        {
          name: "Atendimentos encerrados",
          data: Array.isArray(this.endedConversations)
            ? this.endedConversations.map((el) => el.value)
            : [],
        },
      ],
      chartOptions: {
        chart: {
          height: 250,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        colors: ["#F44336"],

        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          show: false,
        },
        markers: {
          size: 4,
        },
        yaxis: {
          show: false,
        },
        labels: Array.isArray(this.endedConversations)
          ? this.endedConversations.map((el) => el.label)
          : [],
        xaxis: {
          type: "datetime",
          labels: {
            show: false,
          },
        },
      },
      reportPerDay: [],
      compKey: 0,
    };
  },
};
</script>

<style lang="scss" scoped></style>
