<template>
  <div class="vx-col w-full flex-1 sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
    <vx-card>
      <div class="flex items-center justify-between mb-6">
        <div class="flex flex-col space-y-2">
          <h2 class="text-xl font-medium">Motivos de Encerramento</h2>
        </div>
      </div>
      <p v-if="orderedFinishedReasons.length === 0">
        Não há dados registrados nesse período de tempo 😔
      </p>
      <div
        class="mt-4"
        v-for="(item, index) in orderedFinishedReasons"
        :key="index"
      >
        <ul class="flex flex-col space-y-4 mb-4">
          <li>
            <div class="flex justify-between">
              <h4 class="text-lg font-medium">
                {{ item.label }}
              </h4>
              <div>
                <span class="font-bold">{{ item.value }}</span>
                <span class="ml-3 text-primary"
                  >{{ getPorcentage(total, item.value) }}%</span
                >
              </div>
            </div>
            <vs-progress
              :percent="Math.ceil(getPorcentage(total, item.value))"
            ></vs-progress>
          </li>
        </ul>
      </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  methods: {
    getPorcentage(total, porcentage) {
      return total > 0
        ? parseFloat(((100 / total) * porcentage).toFixed(2))
        : 0;
    },
  },
  props: {
    finishReasons: [],
  },
  computed: {
    total() {
      return this.finishReasons.closedConversations &&
        Array.isArray(
          this.finishReasons.closedConversations.map((el) => el.value)
        )
        ? this.finishReasons.closedConversations
            .map((el) => el.value)
            .reduce((a, b) => a + b)
        : [];
    },
    orderedFinishedReasons() {
      const closedArray = [];
      const closed =
        this.finishReasons && this.finishReasons.closed
          ? this.finishReasons.closed
          : null;
      if (closed)
        Object.keys(closed).forEach((key) =>
          closedArray.push({ label: key, value: closed[key] })
        );
      return closedArray;
      /* return closedArray.sort((a, b) =>
        a.value && b.value ? b.value - a.value : false
      ); */
    },
  },
};
</script>

<style lang="scss" scoped></style>
