<template>
  <div class="vx-col w-full flex-1 sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
    <vx-card>
      <div class="flex items-center justify-between mb-6">
        <div class="flex flex-col space-y-2">
          <h2 class="text-xl font-medium">Motivos de Negócio Perdido</h2>
        </div>
        <!-- <feather-icon
          icon="HelpCircleIcon"
          svgClasses="w-6 h-6 text-primary"
        ></feather-icon> -->
      </div>
      <p v-if="orderedLostBusinessReasons.length === 0">
        Não há dados registrados nesse período de tempo 😔
      </p>
      <div
        class="mt-4"
        v-for="(item, index) in orderedLostBusinessReasons"
        :key="index"
      >
        <ul class="flex flex-col space-y-4 mb-4">
          <li>
            <div class="flex justify-between">
              <h4 class="text-lg font-medium">
                {{ item.label }}
              </h4>
              <div>
                <span class="font-bold"> {{ item.value }}</span>
                <span class="ml-3 text-danger"
                  >{{ getPorcentage(total, item.value) }}%</span
                >
              </div>
            </div>
            <vs-progress
              color="danger"
              :percent="Math.ceil(getPorcentage(total, item.value))"
            ></vs-progress>
          </li>
        </ul>
      </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  methods: {
    getPorcentage(total, porcentage) {
      return total === 0 ? 0.0 : ((100 / total) * porcentage).toFixed(2);
    },
  },
  props: {
    lostBusinessReasons: [],
  },
  computed: {
    total() {
      return this.lostBusinessReasons.closed
        ? this.lostBusinessReasons.closed["Negócio Perdido"]
        : null;
    },
    orderedLostBusinessReasons() {
      const closedArray = [];
      const closed =
        this.lostBusinessReasons && this.lostBusinessReasons.closedLoss
          ? this.lostBusinessReasons.closedLoss
          : null;
      if (closed)
        Object.keys(closed).forEach((key) =>
          closedArray.push({ label: key, value: closed[key] })
        );
      return closedArray;
      /* return closedArray.sort((a, b) =>
        a.value && b.value ? b.value - a.value : false
      ); */
    },
  },
};
</script>

<style lang="scss" scoped></style>
