<template>
  <vs-card>
    <div slot="header" class="flex justify-between items-center">
      <h3 class="pl-3 py-3">{{ tableTitle }}</h3>
      <div class="flex">
        <vs-dropdown vs-custom-content vs-trigger-click class="group cursor-pointer">
          <vs-button
            type="border"
            class="py-2 px-2 group-hover:bg-primary"
          >
            <feather-icon
              icon="ColumnsIcon"
              class="group-hover:text-white"
              svgClasses="w-5 h-5"
            />
          </vs-button>
          <vs-dropdown-menu>
            <h4 class="pl-1">Colunas Visíveis</h4>
            <vs-divider class="mt-1" />
            <ul class="w-96">
              <li
                v-for="(item, index) in columnDefs"
                :key="index"
                class="my-1 py-2 hover:bg-grey-light"
                @click="hideColumns.setColumnVisible(item.field, !item.show)"
              >
                <vs-checkbox v-model="item.show">{{ item.headerName }}</vs-checkbox>
              </li>
            </ul>
          </vs-dropdown-menu>
        </vs-dropdown>
        <vs-input
          type="search"
          v-model="search"
          v-on:input="onFilterTextBoxChanged()"
          icon-no-border
          icon-pack="feather"
          icon="icon-search"
          placeholder="Buscar"
          class="ml-3"
        />
      </div>
    </div>
    <vs-row>
      <vs-col vs-w="12">
      </vs-col>
      <vs-col vs-w="12">
        <ag-grid-vue
          class="ag-theme-material my-4 ag-grid-table h-full"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="messagesData"
          :gridOptions="gridOptions"
          rowSelection="single"
          :pagination="true"
          :paginationPageSize="10"
          :suppressPaginationPanel="true"
          @pagination-changed="onPaginationChanged"
          @grid-ready="onGridReady"
          domLayout="autoHeight"
          :overlayNoRowsTemplate="$t('EmptyTable')"
        />
      </vs-col>
      <vs-col v-if="totalPages" vs-w="12" vs-type="flex" vs-justify="end">
        <CustomPagination
          :totalPages="totalPages"
          :currentPage="current"
          @prev="gridOptions.paginationGoToPreviousPage()"
          @next="gridOptions.paginationGoToNextPage()"
          @goToPage="(page) => gridOptions.paginationGoToPage(page)"
        />
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue"
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss"
import CustomPagination from "@/components/CustomPagination.vue";

export default {
  components: {
    AgGridVue,
    CustomPagination
  },
  props: {
    typeData: Array,
    tableTitle: String,
  },
  data() {
    return {
      columnDefs: [
        {
          headerName: 'Nome da Campanha',
          pinned: 'left',
          field: 'name',
          cellDataType: 'text',
          cellClass: 'text-left',
          filter: false,
          show: true,
        }
      ],
      defaultColDef: {
        sortable: true,
        resizable: false,
        suppressMenu: false,
      },
      gridOptions: {},
      messagesData: null,
      search: '',
      current: 1,
      totalPages: null,
      hideColumns: null,
      gridReady: false
    }
  },
  methods: {
    getCsat(score, total) {
      if (!score || !total) return "-";

      const result = (score / total) * 100;
      return result < 100 ? result.toFixed(1) : result;
    },
    getNps(promoters, detractors, totalCountNps) {
      if ((!promoters && !detractors) || totalCountNps === 0) return "-";
      const promotersRate = (promoters / totalCountNps) * 100;
      const detractorsRate = (detractors / totalCountNps) * 100;

      const result = promotersRate - detractorsRate;
      return `${result < 100 ? result.toFixed(1) : result}`;
    },
    getPercentage(total, percentage) {
      if (!total || !percentage) return '0';
      return total > 0 && percentage > -1 ? ((100 / total) * percentage).toFixed(2) : '0';
    },
    transformData() {
      let allReasons = ['Atendimentos Encerrados'];
      const allItems = [];

      if(this.typeData)
        this.typeData.forEach((item) => {
          const currentItem = {};
          currentItem['name'] = item.type || '-';
          Object.keys(item.kpis).forEach((element) => {
            if(element.includes('closedConversations'))
              currentItem['Atendimentos Encerrados'] = item.kpis.closedConversations || 0
            if(!element.includes('closedConversations') && !element.includes('openedConversations'))
              allReasons.push(element);
            currentItem[element] = {total: item.kpis[element], percent: this.getPercentage(item.kpis.closedConversations, item.kpis[element])} || 0;
          });
          currentItem.CSAT = this.getCsat(item.csatScore, item.csatLength);
          currentItem.NPS = this.getNps(item.npsPromotersScore, item.npsDetractorsScore, item.npsLength);
          allItems.push(currentItem);
        })

      allReasons.push('CSAT')
      allReasons.push('NPS')

      allReasons = [...new Set(allReasons)]

      allReasons.forEach(currentReason =>{
        const currentReasonKey = currentReason
        allItems.forEach((currentItem,index) =>{
          if(!allItems[index][currentReasonKey]) allItems[index][currentReasonKey] = 0;
        });
      })

      allReasons.forEach((item) => {
        const columnDef = {
          headerName: item,
          field: item,
          comparator: (firstValue, secondValue) => {
            const finalFirstValue = firstValue.total || firstValue;
            const finalSecondValue = secondValue.total || secondValue;

            if (finalFirstValue == finalSecondValue) return 0;

            return finalFirstValue > finalSecondValue ? 1 : -1;
          },
          cellRenderer: params => {
            if (params.value !== undefined && (params.value.total || params.value.percent)) {
              let percent = params.value.percent;
              if (percent.substring(percent.indexOf('.') + 1).includes('00'))
                percent = percent.substring(0, percent.indexOf('.'))
              return params.value.total + '<span class="bg-primary text-sm text-white p-2 rounded-full ml-2">' + percent + '%</span>'
            } else {
              if (item.includes('Atendimentos Encerrados') || item.includes('CSAT') || item.includes('NPS')) {
                return params.value
              } else {
                if (typeof params.data == 'object') {
                  if (Object.prototype.hasOwnProperty.call(params.data, item) && typeof params.data[item] === 'object') {
                    var total = params.data[item].total;
                    var percent = params.data[item].percent;
                    if (percent.substring(percent.indexOf('.') + 1).includes('00'))
                      percent = percent.substring(0, percent.indexOf('.'))
                    return total + '<span class="bg-primary text-sm text-white p-2 rounded-full ml-2">' + percent + '%</span>'
                  } else {
                    return params.data[item] + '<span class="bg-primary text-sm text-white p-2 rounded-full ml-2">' + params.data[item] + '%</span>'
                  }
                }
                return params.value + '<span class="bg-primary text-sm text-white p-2 rounded-full ml-2">' + params.value + '%</span>'
              }
            }
          },
          filter: false,
          show: true
        };

        if (item === 'tmrValue' || item === 'tmaValue') {
          columnDef.comparator = function (firstValue, secondValue) {
            function removeColonAndParseInt(value) {
              return parseInt(value.replace(':', ''), 10) || 0;
            }

            const firstValueNumeric = typeof (firstValue) === 'number' ? firstValue : removeColonAndParseInt(firstValue);
            const secondValueNumeric = typeof (secondValue) === 'number' ? secondValue : removeColonAndParseInt(secondValue);

            return firstValueNumeric - secondValueNumeric;
          };
        } else if (item === 'CSAT' || item === 'NPS') {
          columnDef.comparator = function (firstValue, secondValue) {

            const parseValue = (value) => {
              if (value === '-') {
                return -1;
              } else {
                return parseFloat(value) || 0;
              }
            };

            const firstValueNumeric = parseValue(firstValue);
            const secondValueNumeric = parseValue(secondValue);

            return firstValueNumeric - secondValueNumeric;
          };
        }

        this.columnDefs.push(columnDef);
      });


      this.messagesData = allItems
    },
    onPaginationChanged() {
      if(this.gridReady) {
        this.current = this.gridOptions.paginationGetCurrentPage()+1
        this.totalPages = this.gridOptions.paginationGetTotalPages()
      }
    },
    onGridReady(params) {
      this.gridOptions = params.api
      this.hideColumns = params.columnApi

      params.api.setRowData(this.messagesData)

      const validColumns = params.columnApi.getAllGridColumns()

      validColumns.forEach(item => {
        if (item.colId.includes('Venda realizada'))
          params.columnApi.moveColumn('Venda realizada', 2)

        if (item.colId.includes('Negócio Perdido'))
          params.columnApi.moveColumn('Negócio Perdido', 3)

        if (item.colId.includes('Inatividade'))
          params.columnApi.moveColumn('Inatividade', 4)

        if (item.colId.includes('Inatividade'))
          params.columnApi.moveColumn('Spam', 5)
      })

      this.gridReady = true
    },
    onFilterTextBoxChanged() {
      this.gridOptions.setQuickFilter(this.search)
    }
  },
  created() {
    this.transformData()
  }
};
</script>
